@mixin sm {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}

@include placeholder {
    color: #8e9aa9;
}

$categories-line-height: 18px;
$input-height: 150px;
$input-line-height: 15px;
$gray-h2: #c7cdd5 !important;
$gray-h2-alternative: #8e9aa9;
$tooltip-font-size:  10px;

$gray-job-position: #202b38;
$gray-borders: #bdc5cf;
$bg-log-color: #eeeeee;
// 
$primary: #516173;
$secondary: #fb8122;
// nav, footer, h1
$tertiary: white;
// jobs h2, checkbox txt, border buttons(options), input txt
$quaternary: #8e9aa9;
// jobs
$quinary: #9c9c9c;
// headers(categories,sections)
$senary: #c7cdd5;
$octonary: #bdc5cf;
$nonary: #c0c4cd;
$bg-gray: #20262d;