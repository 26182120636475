@import "mixins";

// HTML ELEMENTS
* {
  box-sizing: border-box;
}

nav {
  padding: 1rem 0 !important;
}

ul {
  padding: 0 0;
  li {
    list-style: none;
  }
}

a {
  text-decoration: none;
  color: black;
  transition: 0.6s;
}

.input-describe {
  height: $input-height;
}

.input-describe input {
  line-height: $input-line-height;
  padding-bottom: calc(#{$input-height} - #{$input-line-height}) !important;
}

.input-btn-line {
  height: 100%;
}

.done {
  padding-right: 0.5rem;
}

.page-nav a:hover {
  color: black !important;
}

// nav
nav {
  position: fixed !important;
  width: 100%;
  white-space: nowrap;
  z-index: 100;
}

.nav__list--right {
  height: 100%;

  .lang-item{
    text-transform: uppercase;
    display: inline!important;
  }
}
.navbar-toggler{
  padding: 6px 0 0;
  margin-right: 0.25rem;
}
.navbar-collapse > .row{
  padding-top: 30px;
  flex-direction: column;
  .nav__item a{
    margin-left: 0!important;
    font-weight: 400;
    font-size: 16px!important;
  }
  @include sm {
    padding-top: 50px;
    flex-direction: row;
  }
  @include lg {
    padding-top: 0;
    .nav__item a{
      margin-left: 1rem!important;
    }
  }
}

.nav__list--left {
  .nav__item {
    a{
      transition: 0.6s;
      &:hover{
        color:$secondary!important;
     } 
    }
  }
}
.nav__item {
  margin-bottom: 25px;
  @include sm {
    margin-bottom: 0;
  }
}
.navbar>.container-fluid{
  @include xl {
    padding: 0 5%;
  }
}
.space-under-nav {
  padding-top: 67.65px;
  @include lg {
    padding-top: 76.65px;
  }
  box-sizing: content-box;
}
.remove-list-item{
  width: 16px;
  height: 16px;
  display: block;
  background: url(../img/x.svg) no-repeat center;
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer;
}
.job-list {
  padding-left: 16px;
  li{
    position: relative;
    padding-right: 25px;
    max-width: 450px;
    margin-bottom: 5px;
  }
}
// footer
footer {
  margin-top: 5rem !important;
  line-height: 21px;
}

.footer-list {
  height: 100%;
  a:hover{
    text-decoration: underline;
  }
}


.footer-left a {
  text-transform: lowercase;
}

// main-page
.search {
  padding-top: 65.59px;
  padding-bottom: 15px;
  .input-group .search-select{
    visibility: hidden;
    width:260px!important;
    max-width:100%;
    @include md{
      width: auto!important;
    }
  }
  @include md{
    padding-top: 87.59px;
    padding-bottom: 0px;
  }
  
}
.search .select2-container--default .select2-selection--multiple ,
.search input[type="text"] {
  padding-left: 14px;
  border-radius: 8px 0 0 8px;
  min-height: 40px;
  padding-bottom: 0px;
  flex-grow: 1;
  transition: 0.6s;
  background:transparent;
  width:260px;
  border: 1px solid #fb8122!important;
  @include md{
    width: auto;
  }
  ul{
    display:none;
  }
  .select2-selection__rendered{
    overflow: hidden;
    margin:0;
    display: block;
  }
}

.search .select2-container--default .select2-selection--multiple:focus-visible,
.search input[type="text"]:focus-visible {
  outline: none;
}
@media (max-width: 768px) {
  .search .select2-container--default .select2-selection--multiple,
  .search .select2-container--default{
    width:100%!important;
  }
  .search .select2-container--default .select2-selection--multiple ,
  .search input[type="text"]{
    border-radius:8px;
  }
  .add-offer .border-1.border-top.border-dark.text-center{
    display: flex;
    flex-direction: column;
    a{
      order:2;
    }
  }
  .check-wrapper-double{
    padding-bottom:16px;
    border-bottom:1px solid #8e9aa9;
  }
  .btn-filter{
    margin-right: 0;
  }
  .search input[type=button]{
    right: 0;
    top: 0;
    position: absolute!important;
    margin: 0;
  }
}
.input-group{
  textarea{
    position: absolute;
    max-height: 40px;
    opacity: 0;
  }
  .search-select{
    overflow: hidden;
    max-height: 40px;
  }
}
.search .select2-container .select2-search--inline .select2-search__field{
  color:white;
  margin-top:0;
  height: 22px;
}
.search .input-group .select2 .selection .select2-selection .select2-search .select2-search__field{
  margin-left:0;
  font-family: 'Roboto Condensed', sans-serif !important;
}
body .select2-container--default .select2-selection--multiple .select2-selection__choice,
.search .select2-container--default .select2-selection--multiple .select2-selection__choice{
  border: 1px solid #fb8122!important;
  background: #fb8122!important;
  margin-top:-1px;
  margin-bottom: 6px;
}
body .select2-container--default .select2-selection--multiple .select2-selection__choice{
  margin-top:5px;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable,
.search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, 
body .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, 
body .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus,
.search .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus{
  background: #fb8122!important;
}
body .select2-container .select2-search--inline .select2-search__field{
  height:20px;
  font-size: 14px;
}
.search input[type="button"] ,
.search input[type="submit"] {
  position: relative;
  border-radius: 0px 8px 8px 0px !important;
  background-color: $secondary;
  color: $bg-gray !important;
  font-weight:bold;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.6s;
  &:hover{
    background-color: white;
    color: $secondary !important;
  }
}
.modal-header{
  border-bottom: none;
  .modal-title{
    font-weight:bold;
  }
}
.modal-footer{
  justify-content: center;
  padding: 20px;
}
.sticky{
  @include lg{
    position: sticky;
    top:100px;
  } 
  .btn-secondary{
    border-radius: 3px;
  }
}

.job {
  border-bottom-width: 1px;
  border-color: $gray-borders;
  border-bottom-style: solid;
  padding-top: 15px;
  padding-bottom: 10px;
  text-transform: uppercase;
  .row{
    position: relative;
  }
}
.filter__list.active {
  display:block!important;
  position: fixed;
  z-index: 1000;
  background-color: $bg-gray;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0!important;
  padding-top: 50px;
  .close-button{
    position: absolute;
    right: 18px;
    top: 13px;
  }
  .btn-group{
    display: block!important;
    button{
      width: 100%;
      margin-bottom: 15px!important;
    }
  }
  .select2-container .select2-selection--multiple .select2-selection__rendered{
    overflow: hidden;
    white-space: pre-wrap;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
    color: #20262d!important;
    border-right: 1px solid #20262d!important;
    width:auto;
  }
  .dropdown-menu.show{
    position: fixed;
    z-index: 1001;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    margin: 0!important;
    border:none;
    border-radius: 0;
    padding: 50px 18px 100px;
    .close-dropdown-button{
      position: fixed;
      right: 18px;
      top: 13px;
    }
  }
}
.ui-multiselect-checkboxes{
  max-height: 300px;
  overflow-y: auto!important;
}
.filter__list.active .dropdown-menu.show .btn-secondary{
  font-size: 16px!important;
}
.dropdown-menu{
  .custom-select{
    margin-bottom: 5px;
  }
}
.filter{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.filter__list {
  margin-bottom: -0.35% !important;
  position: relative;
  .filter__button{
    margin-right: 0.5rem;
  }
}
.filter__button{
  z-index: 1;
}
.dropdown-container {
  left: 0;
  right: 0;
  margin:auto;
  z-index: 1;
  background-color: #eeeeee;
  padding: 25px 40px;
}

.logo {
  border: 1px;
  border-style: dashed;
  img{
    width: 115px;
    @include lg{
      width: 150px;
    }
  }
}
.footer-logo{
  img{
    width: 115px;
    @include lg{
      width: 150px;
    }
  }
}

.categories__list {
  ul {
    -moz-column-count: 2;
    -moz-column-gap: 10px;
    -webkit-column-count: 2;
    -webkit-column-gap: 10px;
    column-count: 2;
    column-gap: 10px;
    a:hover{
      text-decoration: underline;
    }
  }
}

// registration//login
// registration
.registration-wrapper {
  width: 100%;
  min-height: 100vh;
}

.registration-wrapper>div>div {
  position: relative;
  top: 8%;
  padding-top: 4%;
}

.registration-wrapper input {
  padding-right: 0%;
}

// login
.login-wrapper {
  width: 100%;
  min-height: 100vh;
}

//my offers 
.my-offers-table{
  font-size: 12px;
  @include xl{
    font-size: 14px;
  }
  .font-weight-bold{
    font-size: 11px;
    @include xl{
      font-size: 14px;
    }
  }
  &>div>div {
    max-width:100%;
    border-left:none!important;
    @include lg{
      max-width:145px;
    }
    @include xl{
      max-width:100%;
    }
  }
  &>.row{
    flex-direction: column;
    @include lg{
      flex-direction: row;
    }
  }
  label{
    width:90px;
  }
}

.my-offers {
  img {
    @include xl{
      margin-right: 0.6rem;
    }
    margin-right: 0.4rem;
  }
}
.btn-big-nav{
  .btn-big{
    width:100%;
  }
}

// oferta-pracy
.salary {
  min-height: 50px;
  align-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  @include xxl {
    font-size: 16px;
  }
}
.font-weight-bold{
  font-weight: bold;
}

.requirements-item {
  @include lg {
    width: 200px;
  }
}

.filter a:hover {
  color: black;
  transition: 0.5s;
}

.filter a:active {
  color: black;
}

.filter a:focus {
  color: black;
}

.star {
  position: relative;
  bottom: 14%;
}

.position__company {
  font-size: 17px;
}

// new offer 
.add-offer h2 {
  display: inline;
  font-size: 16px;
  @include sm {
    font-size: 18px;
  }
}

.add-offer img {
  padding-bottom: 0.5rem;
}

.step a {
  text-align: center;
}

// checkout
.check-wrapper {
  min-height: 22px;
  position: relative;
  @include sm {
    min-height: 34px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
   }
   label{
    padding-left: 25px;
    @include sm {
      padding-left: 5px;
     }
     &.btn{
       padding-left: 1rem;
     }
  }
   input[type="checkbox"]{
    display: inline-block;
    top:3px;
    position: absolute;
    @include sm {
      position: relative;
      display: flex;
      top:-1px;
     }
  }
}
.check-wrapper-image{
  position: relative;
  display: block;
  .off{
    display:inline;
    width:40px;
    @include sm {
     width:auto;
    }
  }
  .on{
    display:none;
    width:40px;
    @include sm {
      width:auto;
     }
  }
  &.active{
    .off{
      display:none;
    }
    .on{
      display:inline;
    }
    
  }
  &.check-wrapper-double{
    label:first-child{
      font-weight: bold;;
    }
    &.active{
      label:last-child{
        font-weight: bold;;
      }
      label:first-child{
        font-weight: 400;;
      }
    }
  }
  label{
    padding-left: 55px;
    padding-top: 1px;
    font-size: 14px;
    @include sm {
      font-size: 16px;
      padding-left: 67px;
      padding-top: 5px;
     }
    i{
      position: relative;
      top: -1px;
      left: 2px;
    }
  }
  img{
    position: absolute;    
    z-index: -1;
  }
}

.check-wrapper div {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-basis: 100%;
  margin-bottom: 5px;
  &.custom-btn-radio{
    flex-basis: auto;
  }

}
.far,
.fas,
.fa{
  color: #202b38;
  font-size: 14px;
}
.col-lg .fas{
  margin-right: 10px;
}
.option-content{
  display:none;
  label{
    width:auto;
    display: inline-block;
  }
}
.type-1{
  color:#fb8122;
}
.type-2{
  color:#528987;
}
.type-3{
  color:#c38d9d;
}
.space {
  width: 51px;
}

input[type="text"] {
  padding: 1px 12px;
}

.check-wrapper .custom-select,
.check-wrapper input[type="text"] {
  max-width: 120px;
  align-self: baseline;
  margin-bottom: 0;
  display: inline-block;
  width: 100%;
  @include sm {
    max-width: 130px;
  }
}

.check-wrapper img {
  margin-right: 5px;
  padding-bottom: 0px;
}

input[type="checkbox"] {
  box-sizing: border-box;
  appearance: none;
  border: 1px solid $quaternary;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: auto;
  margin-right: 4px;
  margin-bottom: 2px;
  outline: none;
  cursor: pointer;
  position: relative;
  top: -1px;
}

.ui-multiselect-checkboxes{
  input[type="checkbox"] {
    top:5px;
    display: inline-block;
    &:checked:after {
      display: block;
      position: absolute;
      left: 2px;
    }
  }
  label{
    color: $quaternary;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto Condensed', sans-serif !important;
  }

}
.invoice-data{
  display:none;
  &.show{
    display: flex;
  }
}
.ui-multiselect-header{
  display: none!important;
}

.invisible-checkbox {
  position: absolute!important;
  opacity: 0;
  z-index: -1;
  left:-15px;
}

.off {
  display: none;
}
.active {
  display: block;
}

.float {
  float: left;
  margin-left: -1.5em;
  margin-top: 5px;
}

input[type="checkbox"]::before {
  content: none!important;
}
input[type="checkbox"]::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f00c';
  font-size: 10px;
  color: $quaternary;
  font-weight: 900;
  display: none
}

input[type="checkbox"]:checked::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f00c';
  font-size: 10px;
  color: $quaternary;
  font-weight: 900;
  display: block;
}

label {
  cursor: pointer;
}

.form-check{
  input{
    top:-3px;
  }
  label{
    font-size: 13px;
  }
}

// blog
.blog-card {
  border: 1px solid #c3cbd4;
  border-radius: 5px;
  padding: 15px 15px 40px 15px;
  margin: 20px 0px;

  @include xl {
    flex-basis: 30%;
  }

  position: relative
}

// .blog-card a {
//   position: absolute;
//   bottom: 40px;
//   transform: translate(-50%, 0%);
// }

.blog-card-content {
  .blog-text{
    min-height:100px;
  }
  img{
    width:100%;
  }
}

.blog-card-date {
  color: $nonary;
  font-size: 11px;
}

.blog-card-h2 {
  font-size: 19px;
  font-weight: normal;
  min-height:46px;
}

.blog-card div {
  font-size: 12px;
}