@import "mixins";

// background
.bg-primary {
    background-color: $primary !important;
}

.bg-secondary {
    background-color: $secondary !important;
}

.bg-tertiarty {
    background-color: $tertiary !important;
}

.bg-quaternary {
    background-color: $quaternary !important;
}

.bg-quinary {
    background-color: $quinary !important;
}

.bg-senary {
    background-color: $senary !important;
}

.bg-octonary {
    background-color: $octonary !important;
}

.bg-nav {
    background-color: $bg-gray !important;
}
section.bg-nav{
    top:0;
    z-index: 2;
}
.filter.fixed{
    position: fixed;
    top: 68px;
    left: 0;
    right: 0;
    background: #20262d!important;
    z-index: 10;
    padding-top: 10px;
    .filter__button{
        background: #20262d!important;
    }
}
.share-buttons{
    opacity:0;
    visibility: hidden;
    &.active{
        opacity:1;
        visibility: visible;
    }
}
footer{
    ul{
        padding-top: 20px;
    }
}

.bg-log-color {
    background-color: $bg-log-color;
}

// BORDERS
// border-color
.border-primary {
    border-color: $primary!important;
}

.border-secondary {
    border: 1px solid $secondary !important;
    &:active,
    &:focus,
    &:hover{
        border-color:#5c636a !important;
        outline:none;
    }
}

.border-tertiarty {
    border-color: $tertiary !important;
}
.border-black {
    border-color: $bg-gray !important;
}

.border-nonary {
    border-color: $nonary;
    margin-bottom: 10px;
}

.border-dotted{
    border-style: dotted!important;
}
.dropzone{
    padding: 35px;
}
.calendar-wrapper{
    position: relative;
    .calendar-icon{
        background: url(../img/calendar.png) no-repeat right center;
        background-size: cover;
        height:32px;
        width:32px;
        position: absolute;
        right:0;
        top:0;
    }
}

// border
.rounded-5 {
    border-radius: 2%;
}

.pl-0{
    padding-left: 0!important;;
}
.lang-row{
    position: relative;
    max-width: 900px;
    display: none;
    label{
        font-size: 15px;
        max-width: 145px;
    }
}
.option-content .position-relative,
.education-other-row,
.education-row{
    position: relative;
    display: none;
    label{
        font-size: 15px;
    }
    .close-row{
        top:22px;
    }
}
.close-row{
    position: absolute;
    right:0;
    top:20px;
    z-index: 10;
    @include lg {
        top:45px;
    }
}

.btn {
    text-transform: uppercase;
    padding: .2rem 1rem .2rem;
    border-radius: 4px !important;
    font-size: 12px !important;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    color: $primary;
    font-weight:bold;
    transition: 0.6s;
    @include sm {
        font-size: 14px !important;
    }
    @include md {
        margin-bottom: 0rem;
        font-size: 16px !important;
        padding: .2rem 1rem .1rem;
    }
    &.open,
    &:hover {
        background-color: $secondary;
        color: white !important;
        transition: 0.75s;
        border-color: $secondary !important;
    }
    
    &:focus {
        outline: none;
        background-color: $secondary;
        color: white !important;
        transition: 0.75s;
        border-color: $secondary !important;
        box-shadow: none;
    }
}

// buttons-styles
.btn-outline-primary {
    color: $quaternary;
    border-color: $quaternary;
    box-shadow: 0px 1px 2px 0px $quaternary;
    border-radius: 5px;
    transition:0.6s;
    font-weight: 400;
    &.active{
        background-color: #1e232a;
        color:$tertiary;
        border-color: $quaternary!important;
    }
}

.btn-secondary {
    color: white;
    background-color: $secondary;
    border-radius: 5px;
    text-transform: uppercase;
    min-width: 200px;
    border-color: $secondary;
    font-weight:bold;
    transition:0.6s;
    &:hover {
        background-color: $bg-gray;
        border-color: $bg-gray !important;
    }
}
.btn-outline-secondary{
    border-color: $secondary;
    min-width: auto;
}

.btn-share{
    margin-bottom:1px;
}

.btn-add{
    padding-left: 35px;
    background: url(../img/+-black.svg) no-repeat left 10px center;
    background-size: 14px;
    &:hover {
        background: url(../img/+.svg) no-repeat left 10px center;
        background-color: $bg-gray;
        background-size: 14px;
    }
    &.active{
        background: url(../img/+.svg) no-repeat left 10px center;
        background-size: 14px;
        &:hover {
            background: url(../img/+.svg) no-repeat left 10px center;
            background-color: $bg-gray;
            background-size: 14px;
        }
    }
}

.btn-filter{
    color: white !important;
    background-color: transparent;
    border-color: white !important;
    margin-bottom: 20px;
    &:hover {
        background-color: $secondary;
        border-color: $secondary !important;
    }
}

.filter__button {
    color: $secondary;
    border-color: $primary;
    border-radius: 5px;
    font-size: 12px!important;
    text-transform: uppercase;
    box-shadow: 0px 3px 1px #696969 !important;
    margin-bottom: 0.35rem !important;
    padding: 0.5rem 0.8rem!important;
    &.back-btn{
        box-shadow:none!important;
        border-color: transparent!important;
    }
    @include lg {
        padding: 0.5rem 1.4rem !important;
        font-size: 15px!important;
        &.back-btn{
            box-shadow: 0px 3px 1px #696969 !important;
            border-color: $primary!important;
        }
    }
}
.job__salary{
    font-size: 14px;
    line-height: 1;
    text-align: right;
    @include md {
        position:static;
        margin-top:0px;
        font-size: 16px;
        text-align: left;
    }
}
.job__type{
    align-self: start;
}
.alert{
    border: 2px solid $secondary;
    color: $secondary;
    &.alert-danger{
        margin-top: 25px;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}
.lang-item img{
    border-radius:5px;
    padding-bottom: 3px;
}
.hidden{
    display: none!important;
}
.btn-action{
    font-size: 18px;
}

.btn-md {
    min-width: 120px;
    white-space: nowrap;
}
.btn-lg {
    min-width: 200px;
    white-space: nowrap;
}

.btn-big {
    min-width: 140px;
    white-space: nowrap;
}

.btn-outline-dark {
    color: $quaternary;
    border-color: $primary;
}

.btn-outline-info {
    color: #1f242b !important;
    border-color: #1f242b !important;
    font-weight: 400;
    -webkit-box-shadow: 0px 3px 4px -1px rgba(196, 196, 196, 1);
    -moz-box-shadow: 0px 3px 4px -1px rgba(196, 196, 196, 1);
    box-shadow: 0px 3px 4px -1px rgba(196, 196, 196, 1);
    &.active,
    &:hover{
        background-color: #1f242b!important;
        border-color: #1f242b !important;
        color:#FFF!important;
    }
}

.custom-file{
    color:$quaternary;
    border:1px solid $quaternary;
    padding-left:105px;
    background:url(../img/upload.png) no-repeat left 10px center;
}

.custom-btn-radio{
    input{
        position: absolute;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }
    label:hover{
        color: $quaternary!important;
        border-color: $quaternary!important;
        background: transparent;
    }
    input:checked ~ label {
        background-color: #1e232a;
        color:$tertiary!important;
        border-color: $quaternary!important;
    }
}
.requirements .btn{
    margin-bottom: 4px;
}
.requirements .btn:hover{
    color: $primary!important;
    background:transparent;
    cursor: default;
}

.log-buttons {
    width: 100% !important;
    max-width:180px;
    @include xxl {
        width: 100% !important;
        max-width:190px;
    }
}

// font-styles
.bold {
    font-weight: 700;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.text-coursive {
    font-style: italic;
}

.text-no-wrap {
    white-space: nowrap;
}

.fz-12 {
    font-size: 12px;
}

.fz-14 {
    font-size: 14px;
}

.fz-20 {
    font-size: 20px;
}

// text-color

.text-h2 {
    color: $quaternary !important;
}

.text-primary {
    color: $quaternary !important;
}

.text-secondary {
    color: $secondary !important;
}

.text-tertiary {
    color: $tertiary !important;
}

.text-quaternary {
    color: $quaternary !important;
    font-size:12px;
}

.text-octonary {
    color: $octonary !important;
}

.small-text {
    font-size:14px;
    @include xl{
        font-size: 16px;
    }
}
.text-success {
    color: green;
    font-size:11px;
    @include xl{
        font-size: 12px;
    }
    @include xxl{
        font-size: 14px;
    }
}

.text-alert {
    color: red;
    font-size:11px;
    @include xl{
        font-size: 12px;
    }
    @include xxl{
        font-size: 14px;
    }
}

.text-burgundy {
    color: #800020;
}

// img
.black-inzynio {
    filter: invert(100%);
    -webkit-filter: invert(100%);
}
.error{
    border-color:red;
}
.form-control{
    border-color: $quaternary;
    color:$bg-gray;
    background-color: transparent;
    font-weight:400;
    border-radius: 4px;
    height:32px;
    font-size: 14px;
    display: inline-block;
    cursor:pointer;
    &.error{
        border-color:red;
    }
    &:focus{
        background-color: transparent;
    }
}
select.form-control{
    margin-bottom:0;
}
textarea.form-control{
    height:150px;
    resize:none;
}
.smaller{
    font-size: 12px;
}
.sticky{
    padding: 0.75rem;
    @include sm{
        padding: 0;
    }
}
.new-dropdown,
.add-input-wrapper{
    position: relative;
    .add-options{
        bottom:0;
        right:0;
        position: absolute;
        cursor:pointer;
        img{
            padding-bottom: 0;
        }
    }
}
.new-dropdown .add-options{
    right:0;
}
.fixed-button{
    position:fixed;
    bottom:15px;
    left:15px;
    right:15px;
    button{
        width: 100%;
    }
    &.offer-fixed-button{
        background-color: #FFF;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-bottom: 15px;
        left:0;
        right:0;
        bottom: 0;
    }
}
// inputs
.custom-select {
    position: relative;
    select{
        padding-left: 15px;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        padding-right: 30px;
        &:focus-visible{
            outline: none;
        }
        &:focus{
            background-color: transparent;
        }
        &.selected {
            color: $bg-gray;
        }
    }
    .ui-multiselect{
        padding-left: 15px;
        background-color: white;
        border-color: #8e9aa9;
        border-radius: 4px;
        color: #8e9aa9;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        height: 32px;
        font-family: 'Roboto Condensed', sans-serif !important;
        width: 100%!important;
    }
    .ui-multiselect-open,
    .custom-arrow {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        background-color: $secondary;
        height: 100%;
        width: 2em;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: -1;
        .ui-icon-triangle-1-s{
            opacity: 0;
        }
    }
    .ui-multiselect-open::after,
    .custom-arrow::after {
        --size: 0.4em;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid white;
    }
    
    .custom-arrow-black {
        background-color: $bg-gray;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.custom-arrow {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background-color: $secondary;
    height: 100%;
    width: 2em;
}

.custom-arrow::after {
    --size: 0.4em;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid white;
}

.custom-arrow-black {
    background-color: $bg-gray;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.custom-select .ui-multiselect-open{
    z-index: 1;
}

.filter .dropdown-item{
    transition:background-color 0.6s, color 0.6s, background-position 0s!important;
    border-radius:5px;
    margin-bottom: 5px;
    background-color: transparent;
    &:hover{     
        @include lg {
            background-color: $bg-gray;       
            color:#FFF;
        }
    }
   
    &.active{
        color:#FFF;
        background:url(../img/x-white.png) no-repeat right 10px center $bg-gray;
    }
}

.pagination-wrapper {
    max-height: 30px;
    position: relative;
    display:flex;
    justify-content: center;
    align-content: center;
}

.pagination {
    background-color: $tertiary;
    height: 30px;
    width: 30px;
    border: 1px black solid;
    border-radius: 6px;
    position:static;
}

.pagination-back {
    transform: rotate(90deg);
}

.pagination-next {
    transform: rotate(-90deg);

}

.pagination::after {
    border-top: var(--size) solid;
    --size: 0.4em;
    content: '';
    transform: translate(-50%, -50%);
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
}

.pagination-input {
    max-width: 55px;
    border:none;
    text-align: center;
}

.pagination-label {
    color:$primary;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 4px;
}
.price-list{
    max-height:110px;
    overflow: hidden;
    transition: 0.8s;
    &.opened{
        max-height:500px;
    }
}
.price-toggle{
    cursor:pointer;
    .open{
        display: none;
    }
    &.opened{
        .close{
            display: none;
        }
        .open{
            display: inline;
        }
    }
}
.lang-name{
    font-weight: bold;
    text-transform: uppercase;
}
.limit-education-wrapper{
    display: none;
}
.custom-select {
    min-height: 30px;
}
body .irs--flat .irs-from,
body .irs--flat .irs-to,
body .irs--flat .irs-single,
body .irs--flat .irs-handle>i:first-child,
body .irs--flat .irs-bar {
    background-color: $secondary;
}

.navbar-toggler-line{
    display: block;
    background-color: #fff;
    height: 3px;
    margin-bottom: 6px;
    width: 30px;
    &:last-child{
        margin-bottom: 0;
    }
}
.data__input{
    margin-bottom: 20px;
    @include lg {
        margin-bottom: 0;
    }
}
.my-offers{
    min-height: 540px;
}
.input-group-text{
    height:100%;
}
#quantity {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    margin-top: 4px;
}

#quantity::after {
    content: '';
}
button,
label,
input,
[type="button"],
[type="submit"]{
    appearance: none;
    -webkit-appearance: none;
}

// margin
.fmx-0 {
    margin-right: 0rem;
}

.lmx-0 {
    margin-left: 0rem;
}

.fmx-1 {
    margin-right: 0.25rem;
}

.lmx-1 {
    margin-left: 0.25rem;
}

.fmx-2 {
    margin-right: 0.5rem;
}

.lmx-2 {
    margin-left: 0.5rem;
}

.fmx-3 {
    margin-right: 1rem;
}

.lmx-3 {
    margin-left: 1rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.fmx-lg-0 {
    @include lg {
        margin-right: 0rem;
    }
}

.lmx-lg-0 {
    @include lg {
        margin-left: 0rem;
    }
}

.fmx-lg-1 {
    @include lg {
        margin-right: 0.25rem;
    }
}

.lmx-lg-1 {
    @include lg {
        margin-left: 0.25rem;
    }
}

.fmx-lg-2 {
    @include lg {
        margin-right: 0.5rem;
    }
}

.lmx-lg-2 {
    @include lg {
        margin-left: 0.5rem;
    }
}

.fmx-lg-3 {
    @include lg {
        margin-right: 1rem;
    }
}

.lmx-lg-3 {
    @include lg {
        margin-left: 1rem;
    }
}

.mr-lg-1 {
    @include lg {
        margin-right: 0.25rem;
    }
}

// padding
.fpx-0 {
    padding-right: 0rem;
}

.lpx-0 {
    padding-left: 0rem;
}

.fpx-1 {
    padding-right: 0.25rem;
}

.lpx-1 {
    padding-left: 0.25rem;
}

.fpx-2 {
    padding-right: 0.5rem;
}

.lpx-2 {
    padding-left: 0.5rem;
}

.fpx-3 {
    padding-right: 0.75rem;
}

.lpx-3 {
    padding-left: 0.75rem;
}