body {
    font-family: 'Roboto Condensed', sans-serif !important;
}

h1 {
    font-size: 21px;
    color: $quaternary;
    font-weight: bold;
    text-transform: uppercase;
    @include xl {
        font-size: 22px;
    }

}

h2 {
    font-size: 18px;
    font-weight: bold;
    color: $bg-gray;
    @include md {
        font-size: 22px;
    }
}

h3 {
    font-size: 18px;
}

footer {
    font-size: 12px;

}

footer a {
    color: #FFFFFE;
    text-transform: uppercase;
}

footer a:hover {
    color: white;
}

nav a:hover {
    color: white;
}

a:hover {
    color: black;
}

.form-control.selected,
.form-control:focus {
    outline: none;
    box-shadow: 0px 0px 0px 0px;
    border-color:$secondary;
}

input:hover {
    outline: none;
    box-shadow: 0px 0px 0px 0px;
}



// mainpage
.search input {
    color: white !important;
}

.filter__button span {
    color: $quaternary;
    transition: 0.6s;
    
}
.filter__button.open span,
.filter__button:focus span,
.filter__button:hover span {
    color: $bg-gray;
}

.jobs {
    font-size: 12px;
    margin-top: 10px;
    @include md{
        margin-top: 60px;
    }
}

.jobs h2 {
    color: $quaternary;

}

.categories h2 {
    color: $senary;
    text-transform: uppercase;
}

.categories {
    font-size: 12px;
}

.above-footer {
    p{
        font-size: 12px;
    }
    h2 {
        color: $senary;
    }
}
.job-description,
.duties,
.working-tools,
.benefits{
    font-size:14px;
    line-height: 18px;
    @include md {
        font-size:16px;
        line-height: 24px;
    }
}

.job__position {
    color: $gray-job-position;
    font-weight: bold;
    font-size:15px;
    line-height: 1.2;
    margin-bottom: 0;
    @include md{
        margin-bottom: 0.3rem;
        font-size:16px;
    }
}

.job__company {
    color: $quinary;
    font-size:13px;
    @include md{
        font-size:14px;
    }
}

.job__info {
    color: $quinary;
}

.job__localization {
    color: $gray-job-position;
    line-height: 1;
    font-size:12px;
    padding-bottom: 10px;
    @include sm{
        padding-bottom: 0;
    }
}

// new offer
.new-offer-descrtiption {
    color: $quaternary;
}

.new-offer-descrtiption h2 {
    color: black;
}
@media (min-width: 992px){
    .navbar-expand-lg .navbar-collapse {
        display: block!important;
    }
}

// oferta-pracy